<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <br>
          <b-tabs>
            <b-tab title="Arquivo de Remessa">
              <Shipment />
            </b-tab>
            <b-tab title="Arquivo de Retorno">
              <ReturnShipment />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>
  
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import ReturnShipment from './ReturnShipment.vue'
import Shipment from './Shipment.vue'

import { mapActions, mapMutations } from "vuex";

export default {
  name: "ShipmentReturnView",
  components: { Panel, Button, Alert, Shipment, ReturnShipment },
  data() {
    return {
      panel: {
        module: "Boleto",
        title: "Remessa e Retorno",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  }
};
</script>
