<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <div v-if="!loadingHasShipment">
          <Button v-if="isShipment" _key="btnGenerateShipment" title="Gerar Remessa" type="primary"
            classIcon="fa-solid fa-file-code" size="small" :clicked="generateShipment" />
          <Alert type="info" v-else>
            <span> Não existe Remessa pendente! </span>
          </Alert>
        </div>
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ShipmentView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Alert
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/billing/shipment-return/get-all-shipment",
        showChecks: false,
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "bankAccountName",
            title: "Conta",
            type: "text",
            iconSearch: true,
          },
          {
            field: "registeredDate",
            title: "Emissão",
            type: "dateTime",
          },
          {
            field: "fileName",
            title: "Arquivo",
            type: "text",
          },
          {
            field: "view",
            title: "Visualizado em",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              title: "Baixar",
              classIcon: "fa-solid fa-download",
              type: "primary",
              size: "small",
              eventName: "downloadFileShipment",
            },
          },
        ],
      },
      urlGenerateShipment: "/api/v1/billing/shipment-return/generate-shipment",
      urlHasShipment: "/api/v1/billing/shipment-return/has-shipment",
      urlGetRem: "/api/v1/billing/shipment-return/get-rem",
      isShipment: true,
      loadingHasShipment: true,
    };
  },
  created() {
    this.hasShipment();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    ...mapActions("generic", ["getApi", "getFileApi", "postApi"]),
    hasShipment() {
      this.loadingHasShipment = true;
      let obj = {};
      let params = { url: this.urlHasShipment, obj: obj, notNotifyToast: true };
      this.getApi(params).then((response) => {
        if (response.success) {
          this.isShipment = response.content;
        }
        this.loadingHasShipment = false;
      });
    },
    generateShipment() {
      let obj = {};
      let params = { url: this.urlGenerateShipment, obj: obj, notNotifyToast: true };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.hasShipment();
        }
        this.removeLoading(["btnGenerateShipment"]);
      });
    },
    getRem(data) {
      let params = {
        url: this.urlGetRem,
        obj: {
          tokeShipment: data.token,
          fileName: `${data.fileName}`,
          fileType: "rem"
        },
      };
      this.getFileApi(params).then(() => { });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "downloadFileShipment") {
          this.getRem(event.data);
        }
      },
      deep: true,
    },
  },
};
</script>
