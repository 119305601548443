<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <div v-if="loading">
          <span>Aguarde, estamos processando o retorno, isso pode levar alguns segundos...</span>
          <Loading type="line" :center="false" />
        </div>
        <div v-else>
          <Alert type="info">
            <span>
              Selecione o arquivo de retorno
            </span>
            <br />
          </Alert>
          <FileUpload title="Carregar .ret ou .txt" classIcon="fa-solid fa-file-export" container="nixloc-shipment-return"
            accepted=".ret" allowed=".ret" :disabled="true" urlPost="/api/v1/adm/file-upload/upload"
            urlRemove="/api/v1/adm/file-upload/delete" :nameDataBase="file" v-model="file" :onLoad="processReturn" />
        </div>
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Total Processado" :width="900" :height="750" v-if="showModal('billet')">
      <ScrollBar :minHeight="330" :maxHeight="330">
        <div v-for="billet in jsonProcessed">
          <div class="div-molded">
            <Molded>
              <b-row>
                <b-col sm="1">
                  <div class="div-icon">
                    <i class="fa-solid fa-barcode"></i>
                  </div>
                </b-col>
                <b-col sm="10">
                  <div v-if="billet.Pagador">
                    Cliente: {{ billet.Pagador.Nome }}
                  </div>
                  <div v-else>
                    Cliente: Não Identificado
                  </div>
                  <div v-for="ocorrencia in billet.Ocorrencias">
                    <div class="badge badge-dark-primary" v-if="ocorrencia.Situacao == 'REGISTRO_CONFIRMADO'">
                      Registrado
                    </div>
                    <div class="badge badge-success" v-if="ocorrencia.Situacao == 'LIQUIDACAO'">
                      Liquidado
                    </div>
                    <div class="badge badge-danger" v-if="ocorrencia.Situacao == 'REGISTRO_REJEITADO'">
                      Rejeitado
                    </div>
                    <div class="badge badge-warning" v-if="ocorrencia.Situacao == 'BAIXA'">
                      Baixa
                    </div>
                    {{ ocorrencia.Info.ValorPago | currency }} -
                    {{ ocorrencia.Info.DataDePagamento }}
                  </div>
                </b-col>
              </b-row>
            </Molded>
          </div>
        </div>
      </ScrollBar>
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ReturnShipmentView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Alert,
    FileUpload,
    Loading,
    Modal,
    Molded,
    ScrollBar
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/billing/shipment-return/get-all-return-shipment",
        showChecks: false,
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "registeredDate",
            title: "Emissão",
            type: "dateTime",
          },
          {
            field: "fileName",
            title: "Arquivo",
            type: "text",
            iconSearch: true,
          },
          {
            field: "totalProcessed",
            title: "Total Processado",
            type: "link",
            eventName: "viewBilletProcessed",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              title: "Baixar",
              classIcon: "fa-solid fa-download",
              type: "primary",
              size: "small",
              eventName: "downloadFileReturn",
            },
          }
        ],
      },
      file: "",
      loading: false,
      urlProcessReturn: "/api/v1/billing/shipment-return/process-return",
      jsonProcessed: [],
    };
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["openModal"]),
    processReturn() {
      this.loading = true;
      let self = this;
      setTimeout(function () {
        let obj = { any: self.file };
        let params = { url: self.urlProcessReturn, obj: obj, notNotifyToast: true };
        self.postApi(params).then((response) => {
          if (response.success) {
            self.file = "";
          } else {
            self.file = "";
          }
          self.loading = false;
        });
      }, 300);
    },
    download(fileName) {
      const downloadLink = document.createElement("a");
      downloadLink.href = `https://espaco.blob.core.windows.net/nixloc-shipment-return/${fileName}`;
      downloadLink.target = "_blank";
      downloadLink.click();
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "downloadFileReturn") {
          this.download(event.data.fileNameOriginal);
        }
        if (event.name == "viewBilletProcessed") {
          this.openModal("billet");
          this.jsonProcessed = JSON.parse(event.data.jsonProcessed)
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-molded {
  margin-bottom: 10px;
  margin-right: 10px;
}

.div-icon {
  margin-top: 10px;
}
</style>